import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from 'styled-components';
import MaterialTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import UsersLinks from "components/users/UsersLinks"
import DeleteUserDialog from "components/users/DeleteUserDialog"
import {csrf, headers} from "constants/csrf"
import { flash_alert } from 'components/App';
import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close'; 
import EditProductDialog from "components/products/EditProductDialog"

const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

const columns = [
  
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Plataforma',
    selector: 'platform',
  },
  {
    name: 'TNR',
    selector: 'tnr',
  },
  
  {
    name: 'Nombre',
    selector: 'name',
  },

  {
    name: 'Tipo de producto',
    selector: 'product_type',
  },
  {
    name: 'Adcional de cliente',
    selector: 'additional',
    right: true,
    grow: true,
    cell: row => (
      <div>
        {row.additional ? "Si" : "No"}
      </div>
    ),
  },
  {
    name: 'Acciones',
    selector: 'id',
    right: true,
    grow: true,
    cell: row => (
      <div>
        <EditProductDialog key={"edit-taxon" + row.id} id={row.id} product={row} headers={headers} />
        
      </div>
    ),
  },
];

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <FormControl variant="outlined" >
      <InputLabel className="white-bg padding-sides-5 table-search-label"  htmlFor="search">Filtrar por</InputLabel>
      <OutlinedInput
        id="search"
        type= "text"
        className="table-search-input"
        value={filterText}
        onChange={onFilter}
        endAdornment={
          <InputAdornment className="table-search-end" position="end" >
            <IconButton
										aria-label="toggle password visibility"
										onClick={onClear}
										>
										{<Close />}
										</IconButton>
            
          </InputAdornment>
        }
      />
    </FormControl>
    {/* <UsersLinks /> */}
   </React.Fragment>
);


function getProducts(page = 1, per_page = 10, filterText = "", setproductLoading) {
  return dispatch => {
    dispatch({type: GET_PRODUCTS_REQUEST});
    return fetch(`api/v1/products?all_products=true&page=`+page+`&per_page=`+ per_page+`&keywords=`+ filterText)
      .then(response => response.json())
      .then(json => dispatch(getProductsSuccess(json)) && setproductLoading(false))
      .catch(error => console.log(error)&& setproductLoading(false));
  };
};

export function getProductsSuccess(json) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    json
  };
};



function Products(props){
  const [productLoading, setproductLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [filterText, setFiltertext] = useState("");

  useEffect(() => {
    async function fetchData() {
      setproductLoading(true);
      props.getProducts(page, perPage, filterText, setproductLoading);
    }
    fetchData();
  }, []);


  async function handlePageChange(newPage){
    setproductLoading(true);
    props.getProducts(newPage, perPage, filterText, setproductLoading)
  }

  async function handlePerRowsChange(newPerPage, newPage){
    setproductLoading(true);
    props.getProducts(newPage, newPerPage, filterText, setproductLoading)
    setPerpage(newPerPage);
  }

  function handleChange(state){
   setSelectedRows(state.selectedRows);
  }

  function handleClear(){
    setFiltertext("");
    props.getProducts(page, perPage, "", setproductLoading);
  }

  async function changeFilterText(e){
    setFiltertext(e.target.value);
    const search = document.querySelector('#search');
    props.getProducts(page, perPage, e.target.value, setproductLoading);
  }
  
  return (
    <React.Fragment>
      <MaterialTable
        title="Productos"
        columns={columns}
        data={props.products}
        progressPending={productLoading}
        progressComponent={<CircularProgress size={75} />}
        pagination
        paginationServer
        fixedHeader
        responsive={true}
        fixedHeaderScrollHeight="500px"
        paginationTotalRows={props.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSelectedRowsChange={handleChange}
        highlightOnHover={true}
        striped={true}
        noDataComponent={<h6>No se han encontrado resultados</h6>}
        paginationComponentOptions={{rowsPerPageText: 'Filas por página:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
        subHeader
        subHeaderComponent={<FilterComponent  onFilter={changeFilterText} onClear={handleClear} filterText={filterText} />}
      />
      
    </React.Fragment>
  );
  
}
//  onClick={()=> this.props.getProducts() }

Products.propTypes = {
  products: PropTypes.array,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number
};

const structuredSelector = createStructuredSelector({
  products: state => state.products,
  page: state => state.page,
  perPage: state => state.perPage,
  total: state => state.total,
  curent_user: state => state.curent_user
});
const mapDispatchToProps = {getProducts};
export default withRouter(connect(structuredSelector, mapDispatchToProps)(Products));