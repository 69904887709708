import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from 'styled-components';
import MaterialTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import UsersLinks from "components/users/UsersLinks"
import DeleteUserDialog from "components/users/DeleteUserDialog"
import {csrf, headers} from "constants/csrf"
import { flash_alert } from 'components/App';
import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close'; 
const GET_USERS_REQUEST = "GET_USERS_REQUEST";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

const columns = [
  
  {
    name: 'IR Rol',
    selector: 'role_id',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'ROL',
    selector: 'get_roles_names',
    sortable: true,
    hide: 'sm',
  },
  {
    name: 'Nombre',
    selector: 'fullname',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Correo',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Teléfono fijo',
    selector: 'phone',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Fecha de creación',
    selector: 'created_at',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Acciones',
    selector: 'id',
    right: true,
    grow: true,
    minWidth: "170px",
    cell: row => (
      <div>
      <Link className="mdl-navigation__link brand-primary-link users-edit-link  mg-r-5" to={`/users/${row.id}/edit`}>
          <i className="material-icons material-icons-20">edit</i> Editar
      </Link>
      <DeleteUserDialog key={row.id} user_id={row.id} name={row.firstname} lastname={row.lastname} email={row.email} getUsers={getUsers} headers={headers} />
      </div>
    ),
  },
];

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <FormControl variant="outlined" >
      <InputLabel className="white-bg padding-sides-5 table-search-label"  htmlFor="search">Filtrar por</InputLabel>
      <OutlinedInput
        id="search"
        type= "text"
        className="table-search-input"
        value={filterText}
        onChange={onFilter}
        endAdornment={
          <InputAdornment className="table-search-end" position="end" >
            <IconButton
										aria-label="toggle password visibility"
										onClick={onClear}
										>
										{<Close />}
										</IconButton>
            
          </InputAdornment>
        }
      />
    </FormControl>
    <UsersLinks />
   </React.Fragment>
);


function getUsers(page = 1, per_page = 10, filterText = "") {
  return dispatch => {
    dispatch({type: GET_USERS_REQUEST});
    return fetch(`api/v1/users?page=`+page+`&per_page=`+ per_page+`&keywords=`+ filterText)
      .then(response => response.json())
      .then(json => dispatch(getUsersSuccess(json)))
      .catch(error => console.log(error));
  };
};

export function getUsersSuccess(json) {
  return {
    type: GET_USERS_SUCCESS,
    json
  };
};



function Users(props){
  const [userLoading, setUserLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [filterText, setFiltertext] = useState("");
  //const [selectedRows, setSelectedRows] = useState([]);


  useEffect(() => {
    async function fetchData() {
      props.setLoading(true);
      props.getUsers(page, perPage, filterText);
      props.setLoading(false);
    }
    fetchData();
  }, []);


  async function handlePageChange(newPage){
    props.setLoading(true);
    props.getUsers(newPage, perPage, filterText);
    props.setLoading(false);
  }

  async function handlePerRowsChange(newPerPage, newPage){
    props.setLoading(true);
    props.getUsers(newPage, newPerPage, filterText);
    props.setLoading(false);
    setPerpage(newPerPage);
  }

  function handleChange(state){
   setSelectedRows(state.selectedRows);
  }

  function handleClear(){
    setFiltertext("");
    props.getUsers(page, perPage, "");
  }

  async function changeFilterText(e){
    setFiltertext(e.target.value);
    const search = document.querySelector('#search');
    props.getUsers(page, perPage, e.target.value);
  }
  
  const { loading_var } = userLoading;
  return (
    <React.Fragment>
      <MaterialTable
        title="Usuarios"
        columns={columns}
        data={props.users}
        progressPending={loading_var}
        progressComponent={<CircularProgress size={75} />}
        pagination
        paginationServer
        fixedHeader
        responsive={true}
        fixedHeaderScrollHeight="500px"
        paginationTotalRows={props.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSelectedRowsChange={handleChange}
        highlightOnHover={true}
        striped={true}
        noDataComponent={<h6>No se han encontrado resultados</h6>}
        paginationComponentOptions={{rowsPerPageText: 'Filas por página:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
        subHeader
        subHeaderComponent={<FilterComponent  onFilter={changeFilterText} onClear={handleClear} filterText={filterText} />}
      />
      
    </React.Fragment>
  );
  
}
//  onClick={()=> this.props.getUsers() }

Users.propTypes = {
  users: PropTypes.array,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number
};

const structuredSelector = createStructuredSelector({
  users: state => state.users,
  page: state => state.page,
  perPage: state => state.perPage,
  total: state => state.total,
  curent_user: state => state.curent_user
});
const mapDispatchToProps = {getUsers};
export default withRouter(connect(structuredSelector, mapDispatchToProps)(Users));
