import React from "react"
import ReactDOM from 'react-dom'

import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import NewUser from "components/users/NewUser";
import Button from '@material-ui/core/Button';
class UsersLinks extends React.Component {
  render() {
  	return (
  		<React.Fragment>
		  	<Link id="users-new" className="mdl-navigation__link action-plus-btn" to="/users/new">
		  	<Button variant="outlined" color="primary">
			  <i className="material-icons">person_add</i>&nbsp;&nbsp;Crear Usuario
			</Button>
		  	</Link>
		</React.Fragment>
	);
  }
}

export default UsersLinks
