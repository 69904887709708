import React from "react"
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import { canManage } from 'redux-cancan';
import LanguageSwitcher from "components/LanguageSwitcher"

function NavLinks(props){
	let navMenuItems = [];

	if (canManage('/home')) {
		navMenuItems.push(<Link key="home" className="mdl-navigation__link" to="/"><i className="material-icons">home</i> Home</Link>);
	}
	if (canManage('/users')) {
		navMenuItems.push(<Link key="users" className="mdl-navigation__link" to="/users"><i className="material-icons">group</i> Users</Link>);
	}
	return ReactDOM.createPortal(
		<React.Fragment>
      <LanguageSwitcher />
		    {navMenuItems}
		</React.Fragment>
	,document.getElementById('nav-links')
	)
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default (connect(structuredSelector, mapDispatchToProps)(NavLinks));
