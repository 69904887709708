import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route, Link, withRouter, Redirect, useHistory} from 'react-router-dom'
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import Home from "components/site/Home"
import Users from "components/users/Users"
import NewUser from "components/users/NewUser";
import EditUser from "components/users/EditUser";
import DataUpload from "components/data_upload/DataUpload"
import NavLinks from "components/site/NavLinks"
import SideNavLinks from "components/site/SideNavLinks"
import SiteBreadCrumbs from "components/site/SiteBreadCrumbs"
import Calendar from "components/calendars/Calendar"
import Taxons from "components/taxons/Taxons"
import CircularProgress from '@material-ui/core/CircularProgress';
import NoMatch from "components/site/NoMatch"
import { csrf, headers} from "constants/csrf"
import { flash_alert } from 'components/App';
import Products from 'components/products/Products'
import SidekiqInfo from 'components/site/SidekiqInfo'
const GET_CURRENT_USER = "GET_CURRENT_USER";
function getCurrentUser(user) {
  return dispatch => {
    dispatch(getCurrentUserSuccess(user))
  };
};

export function getCurrentUserSuccess(json) {
  return {
    type: GET_CURRENT_USER,
    json
  };
};


function Routes(props){
		const [redirect, setRedirect] = useState(false);
		const [loading, setLoading] = useState(true);
		
		const history = useHistory();

		useEffect(() => {
			props.getCurrentUser(props.current_user);
			setLoading(true);
			check_permissions(location.pathname)
			setLoading(false);
				history.listen((location, action) => {
				setLoading(true);
				check_permissions(location.pathname);
				setLoading(false);
			});
			},[history.location.pathname]);

			function check_permissions(pathname) {
			return fetch(`/api/v1/abilities/check?location=${pathname}`)
				.then(response => response.json())
				.then(json => {
				console.log(json)
				setRedirect(!json.response)
				if(!json.response){
					flash_alert("No autorizado", "Usted no tiene permisos para realizar esta acción", "danger")
				}
				})
			.catch(error => console.log(error));
		}


			let redirect_check = []
		if (redirect){
			redirect_check.push(
				<Redirect key="redirect-to-home" to="/home"><Home /></Redirect>
			);
		}

		let loading_check = []
		if (loading){
			loading_check.push(
				<div key="loading-container" className="loading-container">
					<CircularProgress key="loading" />
				</div>
			);
		}
	    return (
		  <div>
		  	{redirect_check}
		  	<NavLinks />
    	    <SideNavLinks />
    	    <SiteBreadCrumbs />
		  	{loading_check}
		  	<div className="main">
			    <Switch>
				  {/* Site */}
			      <Route  exact path="/" component={Home}/>
			      <Route path="/home" component={Home}/>
				  {/* Users */}
				  <Route exact path="/users" render={({ match }) => <Users setLoading={setLoading} headers={headers} match={match} />} />
			      <Route exact path="/users/:id/edit" render={({ match }) => <EditUser setLoading={setLoading} headers={headers} match={match} />} />
			      <Route exact path="/users/new" render={({ match }) => <NewUser setLoading={setLoading} headers={headers} match={match} />} />
			      {/* Data upload */}
				  <Route exact path="/data_upload" render={({ match }) => <	DataUpload setLoading={setLoading} env={props.env} headers={headers} match={match} />} />
			      
				  {/* Calendars*/}
				  <Route exact path="/calendar" render={({ match }) => <Calendar setLoading={setLoading} headers={headers} match={match} />} />

				  {/* competitions and categories*/}
				  <Route exact path="/taxons" render={({ match }) => <Taxons setLoading={setLoading} headers={headers} match={match} />} />
			      {/* competitions and categories*/}
				  <Route exact path="/products" render={({ match }) => <Products setLoading={setLoading} headers={headers} match={match} />} />
            <Route exact path="/data_uploads/:data_type" render={({ match }) => <SidekiqInfo loading={loading} setLoading={setLoading} headers={headers} match={match} />} />
			      
				  <Route component={NoMatch} />
			    </Switch>
		    </div>
		  </div>
		);
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {getCurrentUser};
export default (connect(structuredSelector, mapDispatchToProps)(Routes));
