import React from "react"
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import TextField from '@material-ui/core/TextField';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
const names = [
	'Administrador',
	'Contact Center',
	'Technical Management',
	'Field Service',
	'Técnico'
  ];

  
function UserForm(props){

    return (
		<React.Fragment>
        		<form className="custom-form" onSubmit={props.handleSubmit} autoComplete="off">
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" label="Nombre" name="firstname" value={props.firstname} onChange={(e) => props.setFirstname(e.target.value)}/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" label="Apellido Paterno" name="lastname" value={props.lastname} onChange={(e) => props.setLastname(e.target.value)}/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" label="Apellido Materno" name="surname" value={props.surname} onChange={(e) => props.setSurname(e.target.value)}/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" type="email" label="Email" name="email" value={props.email} onChange={(e) => props.setEmail(e.target.value)}/>
						</Grid>
						
						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" label="Telefono Fijo" name="phone" value={props.phone} onChange={(e) => props.setPhone(e.target.value)}/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" label="Telefono Celular" name="cellphone" value={props.cellphone} onChange={(e) => props.setCellphone(e.target.value)}/>
						</Grid>
						

						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" label="Centro de Costo" name="costCenter" value={props.costCenter} onChange={(e) => props.setCostCenter(e.target.value)}/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField fullWidth variant="outlined" label="ID de Rol" name="roleId" value={props.roleId} onChange={(e) => props.setRoleId(e.target.value)}/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" className="MuiFormControl-fullWidth">
								<InputLabel id="demo-simple-select-outlined-label">Jornada</InputLabel>
								<Select
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								value={props.worktime}
								onChange={(e) => props.setWorktime(e.target.value)}
								label="Jornada"
								name="worktime"
								>
								<MenuItem value={"Full-Time"}>Full-Time</MenuItem>
								<MenuItem value={"Part-Time"}>Part-Time</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl className="MuiFormControl-fullWidth Mui-MultiSelect" variant="outlined">
								<InputLabel className="white-bg padding-sides-5" htmlFor="select-multiple-chip" id="role-chip-multiple-label">Rol</InputLabel>
								<Select
								id="role-chip-multiple"
								multiple
								value={props.roleName}
								onChange={(e) => props.setRoleName(e.target.value)}
								input={<OutlinedInput id="select-multiple-chip" />}
								renderValue={(selected) => (
									<div className="">
									{selected.map((value) => (
										<Chip key={value} label={value} className="" />
									))}
									</div>
								)}
								>
								{names.map((name) => (
									<MenuItem key={name} value={name} >
										<Checkbox color="primary" checked={props.roleName.indexOf(name) > -1} />
										<ListItemText primary={name} />
									</MenuItem>
								))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl className={"MuiFormControl-fullWidth custom-from-password "+props.passwordClass} variant="outlined">
								<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
								<OutlinedInput
									id="outlined-adornment-password"
									type={props.showPassword ? 'text' : 'password'}
									value={props.password}
									onChange={(e) => props.setPassword(e.target.value)}
									name="password"
									endAdornment={
									<InputAdornment position="end">
										<IconButton
										aria-label="toggle password visibility"
										onClick={props.handleClickShowPassword}
										onMouseDown={props.handleMouseDownPassword}
										>
										{props.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
									}
									labelWidth={70}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} container
							direction="row"
							justify="flex-end"
							alignItems="center">
							<FormControl>
								<Button id="user-save" type="submit" variant="contained" color="primary">
								Guardar
								</Button>
							</FormControl>
						</Grid>	
					</Grid>
				</form>

	  	</React.Fragment>
    );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(UserForm)
