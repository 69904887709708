import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import Button from '@material-ui/core/Button';
import { flash_alert } from 'components/App';
import Paper from '@material-ui/core/Paper';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import timegridPlugin from "@fullcalendar/timegrid";
import daygridPlugin from "@fullcalendar/daygrid";

import esLocale from '@fullcalendar/core/locales/es';

import Grid from '@material-ui/core/Grid';
import ReactCountryFlag from "react-country-flag";
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
const GET_CALENDAR_EVENTS_REQUEST = "GET_CALENDAR_EVENTS_REQUEST";
const GET_CALENDAR_EVENTS_SUCCESS = "GET_CALENDAR_EVENTS_SUCCESS";
const countries = [
	"MX",
	"CL"
];

const country_names = [{"name": "Chile", "iso": "CL", "phone_code": "+56"}, {"name":"Mexico", "iso": "MX", "phone_code": "+52"}]


/*
 npm install --save @fullcalendar/react @fullcalendar/daygrid
 npm install --save @fullcalendar/interaction
 npm install --save @fullcalendar/core
 npm install --save @fullcalendar/timegrid
 npm install --save @fullcalendar/daygrid
 yarn install --check-files

*/
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function getCalendarEvents(country_iso) {
    return dispatch => {
      dispatch({type: GET_CALENDAR_EVENTS_REQUEST});
      return fetch(`api/v1/calendar_events?country=${country_iso}`)
        .then(response => response.json())
        .then(json => dispatch(getCalendarEventsSuccess(json)))
        .catch(error => console.log(error));
    };
  };
  
  export function getCalendarEventsSuccess(json) {
    console.log(json);
    return {
      type: GET_CALENDAR_EVENTS_SUCCESS,
      json
    };
  };
  
  

function Calendar(props){
    const [open, setOpen] = useState(false);
    const [start_date, setStartDate] = useState(new Date);
    const [finish_date, setFinishDate] = useState(new Date);
    const [allDay, setAllDay] = useState(false);
    const [country, setCountry] = useState("MX");

    useEffect(() => {
        async function fetchData() {
          props.getCalendarEvents(country);
        }
        fetchData();
      }, []);
    

    const handleDateClick = (arg) => { // bind with an arrow function
        console.log(arg);
        //alert(arg.dateStr)
    }

    const handleDateSelect = (arg) => { // bind with an arrow function
        console.log(arg);
        setStartDate(arg.start);
        setFinishDate(arg.end);
        setAllDay(arg.allDay)
        setOpen(true);
    }

    const handleDateUnselect = (arg) => { // bind with an arrow function
        console.log(arg);
    }
    

    const handleClose = () => {
        setOpen(false);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value)
        props.getCalendarEvents(event.target.value);
	};
    const handleCalendarEventCreate = () => {
        var body = new FormData();
        body.set('title', "Feriado Miele");
        body.set('start_date', start_date);
        body.set('finish_date', finish_date);
        body.set('allDay', allDay);
        body.set('event_type', "Blocked");
        body.set('country', country); 
        body.set('description', "Rango de fechas bloqueado para el manejo de eventos");
    
        return axios.post('/api/v1/calendar_events', body, { headers: props.headers})
            .then(response => {
                props.getCalendarEvents(country);
                console.log(response)
                flash_alert("Creado", "El las fechas se han bloqueado satisfactoriamente", "success")
                setOpen(false);
            })
            .catch(e => {
                setOpen(false);
                console.log(e.response.data);
                if(e.response.data){
                    for (var key in e.response.data) {
                        flash_alert("Error", e.response.data[key], "danger")
                    }
                }
        });
        
    };
    const handleCalendarEventDelete = () => {
        var body = new FormData();
        body.set('start_date', start_date);
        body.set('finish_date', finish_date);
        body.set('allDay', allDay);
        body.set('event_type', "Blocked");
        body.set('country', country); 
    
        return axios.post('/api/v1/calendar_events/destroy_event', body, { headers: props.headers})
            .then(response => {
                props.getCalendarEvents(country);
                console.log(response)
                flash_alert("Creado", "El las fechas se han bloqueado satisfactoriamente", "success")
                setOpen(false);
            })
            .catch(e => {
                setOpen(false);
                console.log(e.response.data);
                if(e.response.data){
                    for (var key in e.response.data) {
                        flash_alert("Error", e.response.data[key], "danger")
                    }
                }
        });
    };
    
    
	return (
		<React.Fragment>
            <Paper className="custom-paper">

                <Grid container spacing={3}>

					<Grid item xs={12} sm={6}>
                        <h1>Calendario general</h1>
					</Grid>
					
					<Grid item xs={12} sm={6}>
						<h6>Selecciona un país</h6>
						<FormControl className="MuiFormControl-fullWidth Mui-MultiSelect" variant="outlined">
							<InputLabel className="white-bg padding-sides-5" htmlFor="select-chip-country" id="country-chip-label">País</InputLabel>
							<Select
							id="country-chip"
							value={country}
							onChange={handleCountryChange}
							input={<OutlinedInput id="select-multiple-chip-country" />}
							renderValue={(selected) => (
								<div className="">
									<MenuItem key={selected} value={selected} >
										<ReactCountryFlag
											countryCode={selected}
											svg
											style={{
												width: '2em',
												height: '2em',
												marginRight: "15px",
											}}
											title={selected}
										/>
										<ListItemText primary={country_names.find(object => object["iso"] ===  selected).name} />
									</MenuItem>
								</div>
							)}
							>
							{countries.map((name) => (
								<MenuItem key={name} value={name} >
									<ReactCountryFlag
										countryCode={name}
										svg
										style={{
											width: '2em',
											height: '2em',
											marginRight: "15px",
										}}
										title={name}
									/>
									<ListItemText primary={country_names.find(object => object["iso"] ===  name).name} />
								</MenuItem>
							))}
							</Select>
						</FormControl>
					</Grid>
                    <Grid item xs={12}>
                        <FullCalendar
                        timeZone= {'local'}
                        plugins={[ dayGridPlugin, interactionPlugin,timegridPlugin, daygridPlugin ]}
                        initialView="timeGridWeek"
                        selectable={true}
                        //weekends={false}
                        /*
                        events={[
                            { title: 'event 1', date: '2020-10-11' },
                            { title: 'event 2', date: '2020-10-12' },
                            {
                                groupId: 'testGroupId',
                                start: '2020-10-10T10:00:00',
                                end: '2020-10-12T16:00:00',
                                display: 'background'
                            },
                            {
                            start: '2020-10-24',
                            end: '2020-10-28',
                            overlap: false,
                            display: 'background'
                            },
                            {
                            start: '2020-10-06',
                            end: '2020-10-08',
                            overlap: false,
                            display: 'background'
                            }
                        ]}

                        */
                        events={
                        props.calendar_events
                        }
                        headerToolbar={{
                            start: 'today',
                            center: 'prevYear,prev,title,next,nextYear',
                            end: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        slotLabelFormat={  { month: 'long', year: 'numeric' },
                                            { hour: 'numeric',
                                            minute: '2-digit',meridiem: 'short', hour12: true }}
                        slotLabelContent={ ( arg ) => {
                            let label_text = arg.text; 
                            if(arg.text == "0:00 p. m."){
                                label_text = "12:00 p. m."
                            }
                            return (
                                <>
                                    {label_text}
                                </>
                            );
                        }}
                                            
                        locale={esLocale}
                        dateClick={handleDateClick}
                        select={handleDateSelect}
                        unselect={handleDateUnselect}
                        />
                    </Grid>
                </Grid>
                

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"¿Desea bloquear/desbloquear los rangos seleccionados?"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Las fechas entre <strong>{ (new Date(start_date).toLocaleString("es")) }</strong> a <strong>{(new Date(finish_date).toLocaleString("es"))}</strong> serán bloqueadas y no podran ser usados por los usuarios.
                        <p><strong>Si desbloquea una fecha incluida en un rango enterior, se desbloqueará el rango completo.</strong></p>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleCalendarEventDelete} id="unblock-calendar" color="primary">
                        Desbloquear
                    </Button>
                    <Button onClick={handleCalendarEventCreate} id="block-calendar" color="primary">
                        Bloquear
                    </Button>
                    </DialogActions>
                </Dialog>


            </Paper>
		</React.Fragment>
	);
  
}

const structuredSelector = createStructuredSelector({
    calendar_events: state => state.calendar_events
});
const mapDispatchToProps = {getCalendarEvents};

export default connect(structuredSelector, mapDispatchToProps)(Calendar)
