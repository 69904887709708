import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BuildIcon from '@material-ui/icons/Build';
import StarBorder from '@material-ui/icons/StarBorder';

function SideNavLinks(props){
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    useEffect(() => {
      async function fetchData() {
        var elements = document.getElementsByClassName("MuiListItem-button MuiListItem-button-closer")
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener("click", function(){
            document.querySelector('.mdl-layout__obfuscator').classList.remove('is-visible');
            document.querySelector('.mdl-layout__drawer').classList.remove('is-visible'); 
          });
        }


        var elements = document.getElementsByClassName("side-nav-icon")
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener("mouseenter", function( event ) {   
            document.querySelector('.mdl-layout__obfuscator').classList.add('is-visible');
            document.querySelector('.mdl-layout__drawer').classList.add('is-visible'); 
          }, false);           
        }

        var elements = document.getElementsByClassName("mdl-layout__drawer")
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener("mouseleave", function( event ) {   
            document.querySelector('.mdl-layout__obfuscator').classList.remove('is-visible');
            document.querySelector('.mdl-layout__drawer').classList.remove('is-visible'); 
          }, false); 
        }
      }
      fetchData();
    }, []);
    
     return ReactDOM.createPortal(

      <React.Fragment>
      	
              <Link className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button MuiListItem-button-closer" to="/">
                <i className="material-icons MuiListItemIcon-root side-nav-icon">home</i> Home
              </Link>
              <Link id="users-link" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button MuiListItem-button-closer" to="/users">
                <i className="material-icons MuiListItemIcon-root side-nav-icon">group</i> Usuarios
              </Link>
              <ListItem id="admin-link" button onClick={handleClick}>
                <ListItemIcon>
                  <BuildIcon className="side-nav-icon" />
                </ListItemIcon>
                <ListItemText primary="Administración" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link id="data-upload-link" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button MuiListItem-button-closer" to="/data_upload">
                    <i className="material-icons MuiListItemIcon-root side-nav-icon">cloud_upload</i> Cargar Datos
                  </Link>
                  <Link id="data-upload-link" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button MuiListItem-button-closer" to="/taxons">
                    <i className="material-icons MuiListItemIcon-root side-nav-icon">list</i> Categorías
                  </Link>
                  <Link id="data-upload-link" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button MuiListItem-button-closer" to="/products">
                    <i className="material-icons MuiListItemIcon-root side-nav-icon">inventory</i> Productos
                  </Link>
                  <Link id="calendar-link" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button MuiListItem-button-closer" to="/calendar">
                    <i className="material-icons MuiListItemIcon-root side-nav-icon">calendar_today</i> Calendarios
                  </Link>
                </List>
              </Collapse>
      </React.Fragment>
      ,document.getElementById('side-nav-links')
    )	
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default (connect(structuredSelector, mapDispatchToProps)(SideNavLinks));