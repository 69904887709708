import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {Button, Box} from '@material-ui/core';
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {DropzoneArea} from "material-ui-dropzone";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {csrf, headers} from "constants/csrf";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import {saveAs} from "file-saver";
const MAX_SIZE = 10242880;

function DataUpload(props) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      previewChip: {
        minWidth: 160,
        maxWidth: 210,
      },
    })
  );

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    // name
    document
      .getElementsByClassName("update_customers")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "customers_file";
    document
      .getElementsByClassName("update_taxons")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "taxons_file";
    document
      .getElementsByClassName("update_cost_centers")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "cost_centers_file";
    document
      .getElementsByClassName("update_user_unique_ids")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "user_unique_ids_file";
    document
      .getElementsByClassName("update_administrative_demarcations")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name =
      "administrative_demarcations_file";
    document
      .getElementsByClassName("update_administrative_demarcations")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name =
      "administrative_demarcations_file";
    document
      .getElementsByClassName("update_products")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "file";
    document
      .getElementsByClassName("update_stock_products")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "file";
    document
      .getElementsByClassName("update_service_prices")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "service_prices_file";
    document
      .getElementsByClassName("update_labor_prices")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "labor_prices_file";
    document
      .getElementsByClassName("update_viatic_values")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "viatic_values_file";
    document
      .getElementsByClassName("update_consumables")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "consumables_file";
    document
      .getElementsByClassName("update_checklist")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "checklist_file";
    document
      .getElementsByClassName("update_products_id")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "products_id_file";
    document
      .getElementsByClassName("update_product_prices")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "product_prices_file";


    // id
    document
      .getElementsByClassName("update_customers")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "customers_file";
    document
      .getElementsByClassName("update_taxons")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "taxons_file";
    document
      .getElementsByClassName("update_cost_centers")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "cost_centers_file";
    document
      .getElementsByClassName("update_user_unique_ids")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "user_unique_ids_file";
    document
      .getElementsByClassName("update_administrative_demarcations")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "administrative_demarcations_file";
    document
      .getElementsByClassName("update_products")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "file";
    document
      .getElementsByClassName("update_stock_products")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "file";
    document
      .getElementsByClassName("update_service_prices")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "service_prices_file";
    document
      .getElementsByClassName("update_labor_prices")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "labor_prices_file";
    document
      .getElementsByClassName("update_viatic_values")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "viatic_values_file";
    document
      .getElementsByClassName("update_consumables")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "consumables_file";
    document
      .getElementsByClassName("update_checklist")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "checklist_file";
    document
      .getElementsByClassName("update_products_id")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].name = "products_id_file";
    document
      .getElementsByClassName("update_product_prices")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].id = "product_prices_file";

  }, []);

  function onCustomerChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_customers")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onTaxonsChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_taxons")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onCenterCostFilesChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_cost_centers")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onUserUniqueIdFilesChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_user_unique_ids")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onAdministrativeDemarcationsChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_administrative_demarcations")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onProductsChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_products")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onStockProductsChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
      .getElementsByClassName("update_stock_products")[0]
      .getElementsByClassName("MuiDropzoneArea-root")[0]
      .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onServicePricesChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_service_prices")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onLaborPricesChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_labor_prices")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onViaticValuesChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_viatic_values")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onConsumablesChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_consumables")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onChecklistChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_checklist")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }

  function onProductsIdChangeFiles(e) {
    console.log(e);
    if (e.length > 0) {
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_products_id")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }
  }


  function onProductPricesChangeFiles(e) {
    if (e.length > 0) {
      console.log(e);
      const dT = new DataTransfer();
      dT.items.add(e[0]);
      document
        .getElementsByClassName("update_product_prices")[0]
        .getElementsByClassName("MuiDropzoneArea-root")[0]
        .getElementsByTagName("input")[0].files = dT.files;
    }

  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function download_stock_file() {
    openModal();
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yy = date.getFullYear();
    fetch(window.origin + "/download_actual_stocks_products", {
      headers: {
        "Content-Type": "application/xlsx",
      },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(
          blob,
          "stock_de_productos_" + dd + "-" + mm + "-" + yy + ".xlsx"
        );
        closeModal();
      });
  }

  //evt.dataTransfer.files
  //(files) => files.length > 0 ? document.getElementsByClassName('MuiDropzoneArea-root')[0].getElementsByTagName('input')[0].files = files : undefined
  return (
    <React.Fragment>
      <h1 className="data-upload-section-title">Clientes</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Clientes Miele</h1>
            <a
              href="/api/v1/customers"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_customers"
              id="update_customers"
              action="/update_customers"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"customers_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onCustomerChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/Clientes.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-customer-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Demarcaciones Administrativas por País</h1>
            <a
              href="/api/v1/administrative_demarcations?keywords=CL"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API CL
            </a>{" "}
            &nbsp;&nbsp;
            <a
              href="/api/v1/administrative_demarcations?keywords=MX"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API MX
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_administrative_demarcations"
              id="update_administrative_demarcations"
              action="/update_administrative_demarcations"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"administrative_demarcations_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onAdministrativeDemarcationsChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a
                  href="/AdministrativeDemarcation.xlsx"
                  className="brand-primary-link"
                >
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-administrativedemarcations-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>
      </Grid>

      <h1 className="data-upload-section-title">Productos</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Competencias/Categorias</h1>
            <p>Para técnicos o productos</p>
            <a
              href="/api/v1/taxons"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_taxons"
              id="update_taxons"
              action="/update_taxons"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"taxons_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onTaxonsChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/Taxons.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-taxons-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
              <a
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                href="/data_download/all_taxons"
                style={{marginLeft: '0.5rem'}}
              >
                Descargar Información Actual
              </a>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <Box display="flex" justifyContent="space-between">
              <h1>Productos</h1>
              <Button href="/data_uploads/ProductsUploadWorker" target="_blank" size="small" color="primary" variant="outlined">Ver progreso</Button>
            </Box>
            <p>Por plataforma</p>
            <a
              href="/api/v1/products"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_products"
              id="update_products"
              action="/update_products"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onProductsChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href={`/Products.xlsx`} className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-products-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
              <a
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                href="/data_download/all_products"
                style={{marginLeft: '0.5rem'}}
              >
                Descargar Información Actual
              </a>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">

            <Box display="flex" justifyContent="space-between">
              <h1>Carga de Stock de Productos</h1>
              <Button href="/data_uploads/ProductStocksUploadWorker" target="_blank" size="small" color="primary" variant="outlined">Ver progreso</Button>
            </Box>
            <p>Por País</p>
            <a
              href="/api/v1/products"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_stock_products"
              id="update_stock_products"
              action="/update_stock_products"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onStockProductsChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/Stock_Products.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <p style={{color: "gray"}}>
                *La descarga de información actual requiere de tiempo, debido a
                la cantidad de productos dentro del sistema
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-products-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
              <a
                id="download-stocks"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                href="/download_actual_stocks_products"
                style={{marginLeft: '0.5rem'}}
              >
                Descargar Información Actual
              </a>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Cuestionario Instalación [App Técnicos]</h1>
            <a
              href="/api/v1/checklists"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_checklist"
              id="update_checklist"
              action="/update_checklist"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"checklist_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onChecklistChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/Checklists.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-checklist-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Números de serie de productos (ID)</h1>
            <a
              href="/api/v1/products"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_products_id"
              id="update_products_id"
              action="/update_products_id"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"products_id_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onProductsIdChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/Product_Ids.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-checklist-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>
      </Grid>

      <h1 className="data-upload-section-title">Usuarios</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>ID Centro de costos</h1>
            <a
              href="/api/v1/cost_centers"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_cost_centers"
              id="update_cost_centers"
              action="/update_cost_centers"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"cost_centers_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onCenterCostFilesChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/CostCenters.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-centers-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>ID Usuarios</h1>
            <a
              href="/api/v1/user_unique_ids"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_user_unique_ids"
              id="update_user_unique_ids"
              action="/update_user_unique_ids"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"user_unique_ids_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onUserUniqueIdFilesChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/UserUniqueId.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-user_unique_id-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>
      </Grid>

      <h1 className="data-upload-section-title">Reglas</h1>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Valores diagnostico y reparación</h1>
            <a
              href="/api/v1/service_prices"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_service_prices"
              id="update_service_prices"
              action="/update_service_prices"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"service_prices_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onServicePricesChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/ServicePrices.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-service_prices-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Valores de Mano de obra</h1>
            <a
              href="/api/v1/labor_prices"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_labor_prices"
              id="update_labor_prices"
              action="/update_labor_prices"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"labor_prices_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onLaborPricesChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/LaborPrices.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-labor_prices-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Valores de viáticos</h1>
            <a
              href="/api/v1/viatic_values"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_viatic_values"
              id="update_viatic_values"
              action="/update_viatic_values"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"viatic_values_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onViaticValuesChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/ViaticValues.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-viatic_values-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Valores y asignación de Consumibles</h1>
            <a
              href="/api/v1/consumables"
              target="_blank"
              className="brand-primary-link"
            >
              Ver API
            </a>
            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_consumables"
              id="update_consumables"
              action="/update_consumables"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"consumables_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onConsumablesChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/Consumables.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                id="cargar-consumables-btn"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className="custom-paper data-upload-paper">
            <h1>Actualizar precio de productos</h1>

            <a href="/api/v1/data_uploads?data_type=ProductPrice" target="_blank" className="brand-primary-link">Ver errores</a>
            <br />
            <a href="/api/v1/products" target="_blank" className="brand-primary-link">Ver API</a>

            <br />
            <br />
            <form
              encType="multipart/form-data"
              className="update_product_prices"
              id="update_product_prices"
              action="/update_product_prices"
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf}
              ></input>
              <DropzoneArea
                name={"product_prices_file"}
                dropzoneText={
                  "Arrastre su archivo aqui, o haga click para seleccionarlo"
                }
                onChange={onProductPricesChangeFiles}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: {spacing: 1, direction: "row"},
                }}
                previewChipProps={{classes: {root: classes.previewChip}}}
                previewText="Archivo seleccionado"
                filesLimit={1}
                showAlerts={false}
                maxFileSize={MAX_SIZE}
              />
              <p>
                Debes subir un archivo .XLSX con los datos de los productos que
                deseas cargar. Puedes guiarte por nuestra{" "}
                <a href="/UpdatePrices.xlsx" className="brand-primary-link">
                  plantilla de carga <GetAppIcon />
                </a>
              </p>
              <input
                type="submit"
                name="commit"
                value="Cargar"
                className="mdl-button mdl-js-button mdl-js-ripple-effect btn-primary-custom btn-block btn-miele"
                tabIndex="3"
                data-disable-with="Cargar"
                data-upgraded=",MaterialButton,MaterialRipple"
              />
            </form>
          </Paper>
        </Grid>

      </Grid>


      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <CircularProgress style={{}} size="5em" />
        </Modal>
      </div>
    </React.Fragment>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(
  connect(structuredSelector, mapDispatchToProps)(DataUpload)
);
