export default store => {
  console.log(store.getState())
  const { current_user} = store.getState();

  if (current_user) {
    // protected routes
    switch (current_user.role) {
      case 'admin':
        return ['/home', '/users', '/taxons', '/products', '/sidekiq_info'];
      case 'user_manager':
        return ['/home', '/sidekiq_info'];
      default:
        return ['/home', '/sidekiq_info'];
    }
  }

  return ['/home'];
};
