export const titleLabel = {
  DataUploadWorker: "Carga de productos",
  "ProductStocks::UploadWorker": "Carga de stock"
}

export const statusLabel = {
  pending: "Pendiente",
  processing: "En progreso",
  finished: "Completado",
  failed: "Fallido"
}

export const statusColor = {
  pending: "#3498db",      // Rojo
  processing: "#f1c40f",  // Amarillo
  finished: "#2ecc71",    // Verde
  failed: "#e74c3c"       // Azul
};
