import { createStore, applyMiddleware } from "redux";
import { initCanCan } from 'redux-cancan';
import thunk from "redux-thunk";
const initialState = {
	users: [],
	technicians: [],
	taxons: [],
	products: [],
	user: {},
	technician: {},
	page: 1,
	per_page: 10,
	current_user: {},
	total: 1,
	calendar_events: []
};

function rootReducer(state, action) {
	switch (action.type){
		case "GET_CURRENT_USER":
			return {
				current_user: action.json
			}
		case "GET_USERS_SUCCESS":
			return { users: action.json.data,
					 page: action.json.page,
					 per_page: action.json.per_page,
					 total: action.json.total
			}
		case "GET_TAXONS_SUCCESS":
			return { taxons: action.json.data,
						page: action.json.page,
						per_page: action.json.per_page,
						total: action.json.total
			}
		case "GET_PRODUCTS_SUCCESS":
			return { products: action.json.data,
						page: action.json.page,
						per_page: action.json.per_page,
						total: action.json.total
			}
		
		case "EDITED_PRODUCT":
			const response_product_id = action.response.data.id;
			let products_t = []
			if(state.products){
				products_t = state.products.map(function(product) { return product.id == response_product_id ? action.response.data : product; });
			}
			return { ...state,
					products: products_t
			}
		case "EDITED_TAXON":
			const response_taxon_id = action.response.data.id;
			console.log(action.response)
			let taxons_t = []
			if(state.taxons){
				taxons_t = state.taxons.map(function(taxon) { return taxon.id == response_taxon_id ? action.response.data : taxon; });
			}
			return { ...state,
					taxons: taxons_t
			}
		case "GET_TECHNICIANS_SUCCESS":
				return { technicians: action.json.data,
						 page: action.json.page,
						 per_page: action.json.per_page,
						 total: action.json.total
				}
		case "GET_CALENDAR_EVENTS_SUCCESS":
				return { 
					calendar_events: action.json.data
				}
		case "GET_USER_SUCCESS":
			return { user: action.json.data}
		case "GET_TECHNICIAN_SUCCESS":
			return { technician: action.json.data}
		case "REDIRECT":
			return { redirectTo: action.payload };
		case 'DELETED_USER':
		  	const user_id = action.response.data.user_id;
		  	let users = state.users.filter(user => user.id !== user_id);
		  	return {
		        ...state, 
		        users
			};
		case 'DELETED_TECHNICIAN':
				const technician_id = action.response.data.technician_id;
				let technicians = state.technicians.filter(technician => technician.id !== technician_id);
				return {
				  ...state, 
				  technicians
			  };
		default: 
			return state
	}
}

export default function configureStore(current_user){
	initialState.current_user = current_user
	const store = createStore(rootReducer, initialState, applyMiddleware(thunk));
	initCanCan(store, require('./ability'));
	return store;
}