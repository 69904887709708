import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {csrf, headers} from "constants/csrf"


import { flash_alert } from 'components/App';
const EDIT_TAXON = "EDIT_TAXON";
const EDITED_TAXON = "EDITED_TAXON";

function editTaxon(id, business_unit, alias, family, subfamily, specific, headers) {
   return (dispatch) => {
		dispatch({type: EDIT_TAXON});
		var body = new FormData(); 
		body.set('id', id);
		body.set('business_unit', business_unit);
        body.set('alias', alias);
		body.set('family', family);
		body.set('subfamily', subfamily);
		body.set('specific', specific);
		
        return axios.patch(`/api/v1/taxons/${id}/update_taxon`, body, { headers: headers})
	  	.then(response => {
            console.log(response)
	  		flash_alert("Actualizado!", "La clasificación se ha actualizado correctamente", "success")
            dispatch({type: EDITED_TAXON, response});
	  	})
	    .catch(e => {
	    	flash_alert("Error!", "No se ha podido actualizar el La clasificación", "danger")
	        console.log(e);
		});
	};
};


function EditTaxonDialog(props){
	const [open, setOpen] = useState(false);
	
/*
	TNR (tnr)
	ID (id)
	Business Unit (business_unit)
	Familia (family)
	Sub-familia (subfamily)
	Específico (specific)
	Nombre (product_name)
*/
    const [alias, setAlias] = useState("");
	const [business_unit, setBusinessUnit] = useState("");
	const [family, setFamily] = useState("");
	const [subfamily, setSubFamily] = useState("");
	const [specific, setSpecific] = useState("");

	useEffect(() => {
		setBusinessUnit(props.business_unit)
	},[props.business_unit]);

    useEffect(() => {
		setAlias(props.alias)
	},[props.alias]);

    
    useEffect(() => {
		setFamily(props.family)
	},[props.family]);


    useEffect(() => {
		setSubFamily(props.sub_family)
	},[props.sub_family]);
    
    useEffect(() => {
		setSpecific(props.specific)
	},[props.specific]);

	function handleClickOpen(e){
		e.preventDefault();
		setOpen(true);
	}

	function handleClose(e){
		e.preventDefault();
		setOpen(false);
	}

	async function handleEdit(e){
		e.preventDefault();
        setOpen(false);
        props.editTaxon(props.id, business_unit, alias, family, subfamily, specific,  props.headers);
		
	}

	
	return (
		<React.Fragment>
		  <Link href="#" id={"editaxon_"+props.id} className="mdl-navigation__link brand-primary-link product-edit-link  mg-r-15" onClick={handleClickOpen}><i className="material-icons material-icons-20">edit</i></Link>
		  <Dialog
			open={open}
			fullWidth={true}
        	maxWidth={"lg"}
		    onClose={handleClose}
		    aria-labelledby="alert-dialog-title"
		    aria-describedby="alert-dialog-description"
		  >
		    <DialogTitle id="alert-dialog-title">{"Editar Competencias/Categorías"}</DialogTitle>
		    <DialogContent>
		      <DialogContentText id="alert-dialog-description">
				<Grid container spacing={1}>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Business Unit" name="business_unit" value={business_unit} onChange={(e) => setBusinessUnit(e.target.value)}/>
					</Grid>
                    <Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Business Unit Alias" name="business_unit_alias" value={alias} onChange={(e) => setAlias(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Familia" name="family" value={family} onChange={(e) => setFamily(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Subfamilia" name="subfamily" value={subfamily} onChange={(e) => setSubFamily(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Específico" name="specific" value={specific} onChange={(e) => setSpecific(e.target.value)}/>
					</Grid>

				</Grid>  
		      </DialogContentText>
		    </DialogContent>
		    <DialogActions>
		      <Button onClick={handleClose} variant="outlined" color="primary">
		        Cerrar
		      </Button>
		      <Button onClick={handleEdit} id="product-update"  variant="contained" color="primary" autoFocus>
		        Guardar
		      </Button>
		    </DialogActions>
		  </Dialog>

		  
		</React.Fragment>
	);

}

const structuredSelector = createStructuredSelector({
  customers: state => state.customers
});
const mapDispatchToProps = {editTaxon};

export default connect(structuredSelector, mapDispatchToProps)(EditTaxonDialog)
