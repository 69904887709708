import React from "react"
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import TwoLevelPieChart from "../charts/PieChart";
import SimpleLineChart from "../charts/SimpleLineChart";
import SimpleRadarChart from "../charts/SimpleRadarChart";
import StackedAreaChart from "../charts/StackedAreaChart";
import SimpleBarChart from "../charts/SimpleBarChart";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

function Home(props){
  	return (
     <React.Fragment>
     	<Grid container spacing={3}>
	        <Grid item xs={12} sm={6} md={4}>
	          <Paper className=""><TwoLevelPieChart /></Paper>
	        </Grid>

	        <Grid item xs={12} sm={6} md={4}>
	          <Paper className=""><SimpleLineChart /></Paper>
	        </Grid>

	        <Grid item xs={12} sm={6} md={4}>
	          <Paper className=""><SimpleRadarChart /></Paper>
	        </Grid>

	        <Grid item xs={12} sm={6} md={6}>
	          <Paper className=""><StackedAreaChart /></Paper>
	        </Grid>

	        <Grid item xs={12} sm={6} md={6}>
	          <Paper className=""><SimpleBarChart /></Paper>
	        </Grid>
     	</Grid>
  	</React.Fragment>
	);
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default withRouter(connect(structuredSelector, mapDispatchToProps)(Home));

