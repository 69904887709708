import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { TranslatorProvider, useTranslate } from "react-translate"

const breadcrumbNameMap = {
  '/users': 'Usuarios',
  '/users/new': 'Nuevo',
  '/users/:id/edit': 'Editar',
  '/data_upload': 'Carga de datos',
  '/calendar': "Calendario general",
  '/competitions_and_categories': "Competencias/Categorias"
};

let translations = {
  locale: "es",
  BreadCrumb: {
    "edit": "Editar"
  }
};
 
function BreadCrumbT(text) {
  let t = useTranslate("BreadCrumb");
  return t(text.text)
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));


function SiteBreadCrumbs(props){
  const classes = useStyles();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const history = useHistory();
  useEffect(() => {
    fetchData();
    history.listen((location, action) => {
      fetchData();
    });
  },[history.location.pathname]);

  async function fetchData() {
    let newBreadcrumbs = []
      if (breadcrumbNameMap){
        const pathnames = location.pathname.split('/').filter((x) => x);
        console.log(pathnames)
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          if(last && pathnames.length > 2){
            newBreadcrumbs.push(
                <Typography className="mdl-navigation__link capitalize" key={to}>
                    <BreadCrumbT text={pathnames[pathnames.length - 1]}/>
                </Typography>
            );
          }else{
            if(breadcrumbNameMap[to]){
              newBreadcrumbs.push(
                <Link className="mdl-navigation__link" to={to} key={to}>
                  {breadcrumbNameMap[to]}
                </Link>
              );
            }
          }
        })}
      }
      setBreadcrumbs(newBreadcrumbs);
  }

  return (
      <div className={classes.root}>
        <TranslatorProvider translations={translations}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link className="mdl-navigation__link" to="/">
                Inicio
            </Link>
            {breadcrumbs}  
          
          </Breadcrumbs>
        </TranslatorProvider>
      </div>
  );
}

const structuredSelector = createStructuredSelector({});
const mapDispatchToProps = {};
export default (connect(structuredSelector, mapDispatchToProps)(SiteBreadCrumbs));