import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from 'styled-components';
import MaterialTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import UsersLinks from "components/users/UsersLinks"
import DeleteUserDialog from "components/users/DeleteUserDialog"
import {csrf, headers} from "constants/csrf"
import { flash_alert } from 'components/App';
import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close'; 
import EditTaxonDialog from "components/taxons/EditTaxonDialog"

const GET_TAXONS_REQUEST = "GET_TAXONS_REQUEST";
const GET_TAXONS_SUCCESS = "GET_TAXONS_SUCCESS";

const columns = [
  
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    hide: 'md',
    grow: true,
    minWidth: "60px",
  },
  {
    name: 'País',
    selector: 'taxonomy.country.name',
    sortable: true,
    hide: 'sm',
    grow: true,
    minWidth: "75px",
  },
  {
    name: 'Plataforma',
    selector: 'taxonomy.name',
    sortable: true,
    hide: 'md',
    grow: true,
    minWidth: "100px",
  },
  {
    name: 'Business Unit',
    selector: 'business_unit.name',
    sortable: true,
  },
  {
    name: 'Familia',
    selector: 'family.name',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Subfamilia',
    selector: 'sub_family.name',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Específico',
    selector: 'name',
    sortable: true,
    hide: 'md',
  },
  {
    name: 'Acciones',
    selector: 'id',
    right: true,
    grow: true,
    cell: row => (
      <div>
        <EditTaxonDialog key={"edit-taxon" + row.id} id={row.id} business_unit={row.business_unit.name} alias={row.business_unit.alias}  family={row.family.name} sub_family={row.sub_family.name} specific={row.name} headers={headers} />
        
      </div>
    ),
  },
];

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <FormControl variant="outlined" >
      <InputLabel className="white-bg padding-sides-5 table-search-label"  htmlFor="search">Filtrar por</InputLabel>
      <OutlinedInput
        id="search"
        type= "text"
        className="table-search-input"
        value={filterText}
        onChange={onFilter}
        endAdornment={
          <InputAdornment className="table-search-end" position="end" >
            <IconButton
										aria-label="toggle password visibility"
										onClick={onClear}
										>
										{<Close />}
										</IconButton>
            
          </InputAdornment>
        }
      />
    </FormControl>
    {/* <UsersLinks /> */}
   </React.Fragment>
);


function getTaxons(page = 1, per_page = 10, filterText = "") {
  return dispatch => {
    dispatch({type: GET_TAXONS_REQUEST});
    return fetch(`api/v1/taxons_for_core?page=`+page+`&per_page=`+ per_page+`&keywords=`+ filterText)
      .then(response => response.json())
      .then(json => dispatch(getTaxonsSuccess(json)))
      .catch(error => console.log(error));
  };
};

export function getTaxonsSuccess(json) {
  return {
    type: GET_TAXONS_SUCCESS,
    json
  };
};



function Taxons(props){
  const [taxonLoading, setTaxonLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [filterText, setFiltertext] = useState("");

  useEffect(() => {
    async function fetchData() {
      props.setLoading(true);
      props.getTaxons(page, perPage, filterText);
      props.setLoading(false);
    }
    fetchData();
  }, []);


  async function handlePageChange(newPage){
    props.setLoading(true);
    props.getTaxons(newPage, perPage, filterText);
    props.setLoading(false);
  }

  async function handlePerRowsChange(newPerPage, newPage){
    props.setLoading(true);
    props.getTaxons(newPage, newPerPage, filterText);
    props.setLoading(false);
    setPerpage(newPerPage);
  }

  function handleChange(state){
   setSelectedRows(state.selectedRows);
  }

  function handleClear(){
    setFiltertext("");
    props.getTaxons(page, perPage, "");
  }

  async function changeFilterText(e){
    setFiltertext(e.target.value);
    const search = document.querySelector('#search');
    props.getTaxons(page, perPage, e.target.value);
  }
  
  const { loading_var } = taxonLoading;
  return (
    <React.Fragment>
      <MaterialTable
        title="Competencias/Categorías"
        columns={columns}
        data={props.taxons}
        progressPending={loading_var}
        progressComponent={<CircularProgress size={75} />}
        pagination
        paginationServer
        fixedHeader
        responsive={true}
        fixedHeaderScrollHeight="500px"
        paginationTotalRows={props.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSelectedRowsChange={handleChange}
        highlightOnHover={true}
        striped={true}
        noDataComponent={<h6>No se han encontrado resultados</h6>}
        paginationComponentOptions={{rowsPerPageText: 'Filas por página:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
        subHeader
        subHeaderComponent={<FilterComponent  onFilter={changeFilterText} onClear={handleClear} filterText={filterText} />}
      />
      
    </React.Fragment>
  );
  
}
//  onClick={()=> this.props.getTaxons() }

Taxons.propTypes = {
  taxons: PropTypes.array,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number
};

const structuredSelector = createStructuredSelector({
  taxons: state => state.taxons,
  page: state => state.page,
  perPage: state => state.perPage,
  total: state => state.total,
  curent_user: state => state.curent_user
});
const mapDispatchToProps = {getTaxons};
export default withRouter(connect(structuredSelector, mapDispatchToProps)(Taxons));