import React from 'react'
import moment from 'moment'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@material-ui/lab';
import { statusColor, statusLabel } from './constants';

const StatusChangeItem = (props) => {
  const { status, timestamp, isLastStatus = false } = props
  return (
    <TimelineItem>
      <TimelineOppositeContent color="textSecondary">
        {moment(timestamp).format('DD/MM/YYYY HH:mm:ss')}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={{color: statusColor[status], backgroundColor: statusColor[status]}} />
        {!isLastStatus && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>{statusLabel[status]}</TimelineContent>
    </TimelineItem>
  )
}

function StatusChanges(props) {
  const {data: {status_changes}} = props 
  console.log(status_changes.length);

  return (
    <Timeline>
      {
        status_changes.map((statusChange, index) => (
          <StatusChangeItem isLastStatus={status_changes.length === (index+1)} key={statusChange.timestamp} status={statusChange.status} timestamp={statusChange.timestamp} />
        ))
      }
    </Timeline>
  )
}

export default StatusChanges
