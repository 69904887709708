import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {csrf, headers} from "constants/csrf"


import { flash_alert } from 'components/App';
const EDIT_PRODUCT = "EDIT_PRODUCT";
const EDITED_PRODUCT = "EDITED_PRODUCT";

function editProduct(id, platform, tnr, ean, profit_center, name, description, mandatory, product_type, additional, home_program_hours, all_countries, stockcl, stockmx, stockbr, headers) {
   return (dispatch) => {
		dispatch({type: EDIT_PRODUCT});
		var body = new FormData(); 
		body.set('platform', platform);
		body.set('tnr', (tnr && tnr || ""));
		body.set('ean', (ean && ean || ""));
		body.set('profit_center', (profit_center && profit_center || ""));
		body.set('name', (name && name || ""));
		body.set('description', (description && description || ""));
		body.set('mandatory', mandatory);
		body.set('product_type', product_type);
		body.set('additional', additional);
		body.set('home_program_hours', home_program_hours);
		body.set('all_countries', all_countries);
		body.set('id', id);
		stockcl && body.set('stockcl', stockcl);
		stockmx && body.set('stockmx', stockmx);
		stockbr && body.set('stockbr', stockbr);
        return axios.patch(`/api/v1/products/${id}`, body, { headers: headers})
	  	.then(response => {
            console.log(response)
	  		flash_alert("Actualizado!", "El producto se ha actualizado correctamente", "success")
            dispatch({type: EDITED_PRODUCT, response});
	  	})
	    .catch(e => {
	    	flash_alert("Error!", "No se ha podido actualizar el El producto", "danger")
	        console.log(e);
		});
	};
};


function EditProductDialog(props){
	const [open, setOpen] = useState(false);
	
/*
	platform: string
	tnr: string
	ean: string
	profit_center: string
	name: string
	description: string
	mandatory: boolean
	product_type: string
	additional: boolean
	home_program_hours: float
	all_countries: boolean
	*/

	const [platform, setplatform] = useState("");
	const [tnr, settnr] = useState("");
	const [ean, setean] = useState("");
	const [profit_center, setprofit_center] = useState("");
	const [name, setname] = useState("");
	const [description, setdescription] = useState("");
	const [mandatory, setmandatory] = useState(false);
	const [product_type, setproduct_type] = useState("");
	const [additional, setadditional] = useState(false);
	const [home_program_hours, sethome_program_hours] = useState(0.0);
	const [all_countries, setall_countries] = useState(false);

	const [stockcl, setStockcl] = useState(null);
	const [stockmx, setStockmx] = useState(null);
	const [stockbr, setStockbr] = useState(null);

	useEffect(() => {
		if(props.product){
			setplatform(props.product.platform)
			settnr(props.product.tnr)
			setean(props.product.ean)
			setprofit_center(props.product.profit_center)
			setname(props.product.name)
			setdescription(props.product.description)
			setmandatory(props.product.mandatory)
			setproduct_type(props.product.product_type)
			setadditional(props.product.additional)
			sethome_program_hours(props.product.home_program_hours)
			setall_countries(props.product.all_countries)
			if (props.product.product_stocks.length > 0){
				let stock_mx = props.product.product_stocks.find(stock => stock.country_id === 1);
				let stock_cl = props.product.product_stocks.find(stock => stock.country_id === 2);
				let stock_br = props.product.product_stocks.find(stock => stock.country_id === 3);
				if(stock_mx){
					setStockmx(stock_mx.stock)
				}
				if(stock_cl){
					setStockcl(stock_cl.stock)
				}
				if(stock_br){
					setStockbr(stock_br.stock)
				}
				
			}
		}

	},[props.product]);



	function handleClickOpen(e){
		e.preventDefault();
		setOpen(true);
	}

	function handleClose(e){
		e.preventDefault();
		setOpen(false);
	}

	async function handleEdit(e){
		e.preventDefault();
        setOpen(false);
        props.editProduct(props.id, platform, tnr, ean, profit_center, name, description, mandatory, product_type, additional, home_program_hours, all_countries, stockcl, stockmx, stockbr, props.headers); 
		
	}

	
	return (
		<React.Fragment>
		  <Link href="#" id={"editaxon_"+props.id} className="mdl-navigation__link brand-primary-link product-edit-link  mg-r-15" onClick={handleClickOpen}><i className="material-icons material-icons-20">edit</i></Link>
		  <Dialog
			open={open}
			fullWidth={true}
        	maxWidth={"lg"}
		    onClose={handleClose}
		    aria-labelledby="alert-dialog-title"
		    aria-describedby="alert-dialog-description"
		  >
		    <DialogTitle id="alert-dialog-title">{"Editar Producto " + name}</DialogTitle>
		    <DialogContent>
		      <DialogContentText id="alert-dialog-description">
				<Grid container spacing={1}>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Nombre" name="name" value={name} onChange={(e) => setname(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Platform" name="platform" value={platform} onChange={(e) => setplatform(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Tnr" name="tnr" value={tnr} onChange={(e) => settnr(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Ean" name="ean" value={ean} onChange={(e) => setean(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Profit Center" name="profit_center" value={profit_center} onChange={(e) => setprofit_center(e.target.value)}/>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Descripción" name="description" value={description} onChange={(e) => setdescription(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" label="Tipo de producto" name="product_type" value={product_type} onChange={(e) => setproduct_type(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" type="number" label="Horas de home program" name="home_program_hours" value={home_program_hours} onChange={(e) => sethome_program_hours(e.target.value)}/>
					</Grid>
				</Grid>

				<h3>Stocks</h3>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" type="number" label="Chile" name="cl" value={stockcl} onChange={(e) => setStockcl(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" type="number" label="Mexico" name="mx" value={stockmx} onChange={(e) => setStockmx(e.target.value)}/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<TextField fullWidth variant="outlined" type="number" label="Brasil" name="br" value={stockbr} onChange={(e) => setStockbr(e.target.value)}/>
					</Grid>
				</Grid>
				
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12}>
						<FormControlLabel
							className=""
							control={
							<Checkbox
								checked={mandatory}
								onChange={(e) => setmandatory(!mandatory)}
								name="mandatory"
								color="primary"
							/>
							}
							label="Mandatory"
						/>
					</Grid>

					<Grid item xs={12} sm={12}>
						<FormControlLabel
							className=""
							control={
							<Checkbox
								checked={additional}
								onChange={(e) => setadditional(!additional)}
								name="additional"
								color="primary"
							/>
							}
							label="Additional"
						/>
					</Grid>

					<Grid item xs={12} sm={12}>
						<FormControlLabel
							className=""
							control={
							<Checkbox
								checked={all_countries}
								onChange={(e) => setall_countries(!all_countries)}
								name="all_countries"
								color="primary"
							/>
							}
							label="Todos los países"
						/>
					</Grid>
							
				</Grid>
					
		      </DialogContentText>
		    </DialogContent>
		    <DialogActions>
		      <Button onClick={handleClose} variant="outlined" color="primary">
		        Cerrar
		      </Button>
		      <Button onClick={handleEdit} id="product-update"  variant="contained" color="primary" autoFocus>
		        Guardar
		      </Button>
		    </DialogActions>
		  </Dialog>

		  
		</React.Fragment>
	);

}

const structuredSelector = createStructuredSelector({
  customers: state => state.customers
});
const mapDispatchToProps = {editProduct};

export default connect(structuredSelector, mapDispatchToProps)(EditProductDialog)
